import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { isEqual, map, reduce, set } from 'lodash/fp';
import { Component, createElement } from 'react';
import ToolbarButton from './button';
import Insert from './Insert';
import './style.m.less';
const ToolbarGroup = ({ children, rightAlign }) => (_jsx("span", { className: classNames('ql-formats', { right: rightAlign }), children: children }));
const FormatButton = ({ className, format, value, title }) => (_jsx(ToolbarButton, { className: classNames(format, className), value: value, title: title }));
const partitionComponents = (comps = []) => reduce((mem, c) => {
    const container = mem[c.type];
    if (container) {
        container.push(c);
    }
    return mem;
}, { button: [], insertDropdown: [], aiButton: [] }, comps);
const getFormatsMap = reduce((mem, f) => set([f], true, mem), {});
export default class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.getLastSelection = () => {
            if (this.lastSelection) {
                return this.lastSelection;
            }
            if (!this.quill) {
                return null;
            }
            return { index: this.quill.getLength() - 1, length: 0 }; // - 1 because there's always a \n at the end
        };
        this.render = () => {
            const { quill, getLastSelection } = this;
            const { toolbarId, formats, moduleComponents, writingAssistant } = this.props;
            const fs = getFormatsMap(formats);
            const components = partitionComponents(moduleComponents);
            return (_jsxs("div", { id: toolbarId, className: "ql-toolbar ql-snow", children: [_jsxs(ToolbarGroup, { children: [fs.bold && _jsx(FormatButton, { format: "ql-bold", title: "Bold" }), fs.italic && _jsx(FormatButton, { format: "ql-italic", title: "Italic" }), fs.underline && _jsx(FormatButton, { format: "ql-underline", title: "Underline" }), fs.strike && _jsx(FormatButton, { format: "ql-strike", title: "Strikethrough" })] }), _jsxs(ToolbarGroup, { children: [fs.list && _jsx(FormatButton, { format: "ql-list", value: "ordered", title: "Ordered List" }), fs.list && _jsx(FormatButton, { format: "ql-list", value: "bullet", title: "Unordered List" }), fs.indent && _jsx(FormatButton, { format: "ql-indent", value: "-1", title: "Outdent" }), fs.indent && _jsx(FormatButton, { format: "ql-indent", value: "+1", title: "Indent" })] }), _jsxs(ToolbarGroup, { children: [fs['code-block'] && _jsx(FormatButton, { format: "ql-code-block", title: "Code Block" }), fs.link && _jsx(FormatButton, { format: "ql-link", title: "Link" })] }), _jsx(ToolbarGroup, { children: map(({ key, component }) => createElement(component, { key, quill, getLastSelection }), components.button) }), !!components.insertDropdown.length && (_jsx(ToolbarGroup, { children: _jsx(Insert, { quill: quill, getLastSelection: getLastSelection, options: components.insertDropdown }) })), writingAssistant && writingAssistant.enabled && (_jsx(ToolbarGroup, { rightAlign: true, children: createElement(components.aiButton[0].component, {
                            key: components.aiButton[0].key,
                            quill,
                            getLastSelection,
                            config: writingAssistant,
                        }) }))] }));
        };
        this.quill = null;
        this.lastSelection = null;
    }
    trySetupQuill(quill) {
        if (this.quill || !quill) {
            return;
        }
        this.quill = quill;
        quill.on('editor-change', () => {
            const selection = quill.getSelection();
            if (selection) {
                this.lastSelection = selection;
            }
        });
    }
    UNSAFE_componentWillMount() {
        this.trySetupQuill(this.props.quill);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.trySetupQuill(nextProps.quill);
    }
    shouldComponentUpdate(nextProps) {
        const { formats, moduleComponents, quill, writingAssistant } = this.props;
        if (!isEqual(formats, nextProps.formats)) {
            return true;
        }
        if (!isEqual(moduleComponents, nextProps.moduleComponents)) {
            return true;
        }
        if (!isEqual(writingAssistant, nextProps.writingAssistant)) {
            return true;
        }
        if (!quill && nextProps.quill) {
            return true;
        }
        return false;
    }
}
