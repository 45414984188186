import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { INTEGRATIONS_SLACK_CONFIGURATION } from '../../../../routes';
import DeprecatedButtonContainer from '../../../components/ButtonContainer';
import { Grid, GridItem } from '../../../components/Grid';
import ImageWithAspectRatio from '../../../components/ImageWithAspectRatio';
import Link from '../../../components/Link';
import Modal from '../../../components/Modal';
import { prependAppPath } from '../../../services/Url';
import styles from './style.m.less';
import { preventJavascriptUrls } from '../../../../services/security';
export default function ChatPromotionModal({ isOpen, onClose }) {
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, children: _jsxs(ChatDescription, { title: "Keep the conversation going", screenshot: require('./slack-ui.png'), href: prependAppPath(INTEGRATIONS_SLACK_CONFIGURATION), label: "Configure Slack", width: 543, height: 343, children: [_jsx("p", { children: "Managers stay up-to-date with the latest objectives created by their teams. Employees shine with Praise that is easy to send and visible right in Slack. And the difference between a fast, helpful feedback talk and barely remembering what happened since the last one is just a private /note away." }), _jsx("p", { children: _jsx(Link, { href: "https://help.small-improvements.com/article/267-slack-integration", openInNewTab: true, children: "Learn more in our documentation." }) })] }) }));
}
function ChatDescription({ title, href, label, screenshot, children, width, height }) {
    return (_jsxs("div", { children: [_jsx("h1", { children: title }), _jsxs(Grid, { children: [_jsx(GridItem, { desktop: "half", s: "full", children: children }), _jsx(GridItem, { desktop: "half", s: "full", children: _jsx(ImageWithAspectRatio, { width: width, height: height, className: styles.screenshot, src: screenshot }) })] }), _jsx(DeprecatedButtonContainer, { children: _jsx("a", { className: "button save", href: preventJavascriptUrls(href), children: label }) })] }));
}
