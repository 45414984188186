import { createElement } from 'react';
import classNames from 'classnames';
import { curry } from 'lodash';
export const componentWithClassName = curry((componentOrTag, otherClassName, { children = undefined, className = undefined, style = undefined, id = undefined }) => {
    const props = {
        style,
        id,
        className: classNames(otherClassName, className),
    };
    return createElement(componentOrTag, props, children);
});
export const divWithClassName = componentWithClassName('div');
