import AutocompletionBlot, { mkSerialize } from '../../helpers/autocompletion/blot';
import { trim, find, replace, noop } from 'lodash/fp';
import { REPLACEMENTS, matchesReplacement } from './replacements';
class Replacement extends AutocompletionBlot {
}
const deserializeBlot = (match, rawReplacement) => {
    const serialization = `{{ ${trim(rawReplacement)} }}`;
    const choice = find(r => matchesReplacement(serialization, r.replacement), REPLACEMENTS);
    return choice ? Replacement.create({ value: choice.value, serialization }).outerHTML : match;
};
const matchSerializedBlot = /{{\s?(.+?)\s?}}/g;
Replacement.blotName = 'replacement';
Replacement.className = 'rte-replacement';
Replacement.siSerialize = mkSerialize(noop);
Replacement.siDeserialize = replace(matchSerializedBlot, deserializeBlot);
export default Replacement;
