import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import c from 'classnames';
import moment from 'moment';
import { useState, useCallback, useMemo } from 'react';
import Calendar from '../../Calendar';
import styles from './styles.m.less';
import { IconAlertCircle } from 'featherico';
export default function Popup({ date, onChange, validRange, onMouseDown, highlightedDates, errorMessage }) {
    const [month, setMonth] = useState((date ? date.clone() : moment()).startOf('month'));
    const handleDaySelect = useCallback((d) => onChange(fromDate(d)), [onChange]);
    const handlePrevMonthClick = useMemo(() => {
        if (!validRange.from || month.isAfter(validRange.from, 'month')) {
            return () => setMonth(m => m.clone().subtract(1, 'month'));
        }
        else {
            return undefined;
        }
    }, [month, validRange.from]);
    const handleNextMonthClick = useMemo(() => {
        if (!validRange.to || month.isBefore(validRange.to, 'month')) {
            return () => setMonth(m => m.clone().add(1, 'month'));
        }
        else {
            return undefined;
        }
    }, [month, validRange.to]);
    return (_jsxs("div", { className: styles.container, "aria-hidden": true, onMouseDown: onMouseDown, children: [_jsx(Calendar, { month: formatMonth(month), from: date && formatDate(date), to: date && formatDate(date), onDayClick: handleDaySelect, onPrevMonthClick: handlePrevMonthClick, onNextMonthClick: handleNextMonthClick, highlightedDates: highlightedDates, validRange: validRange, fixedHeight: false }), errorMessage && (_jsx("div", { className: c(styles.summary, { [styles.error]: errorMessage }), children: _jsxs(_Fragment, { children: [_jsx(IconAlertCircle, {}), " ", errorMessage] }) }))] }));
}
function formatDate(date) {
    return date.format(moment.HTML5_FMT.DATE);
}
function fromDate(date) {
    return moment(date, moment.HTML5_FMT.DATE);
}
function formatMonth(month) {
    return month.format('YYYY-MM');
}
