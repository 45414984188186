import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Children, Component } from 'react';
import { isFunction, isUndefined } from 'lodash';
import Modal from '../Modal';
import DeprecatedButtonContainer from '../ButtonContainer';
import Button from '../Button';
import classNames from 'classnames';
import SlideShow from '../SlideShow';
import SIZES from '../Modal/sizes';
const propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func,
    size: PropTypes.oneOf(Object.keys(SIZES)),
    height: PropTypes.number.isRequired, // for now
    backgroundImage: PropTypes.string,
    nextLabel: PropTypes.any,
    doneLabel: PropTypes.any,
    page: PropTypes.number,
    onPageMove: PropTypes.func,
};
const DEFAULT_SIZE = 'LG_WIDTH';
export function MultiModalPage({ children }) {
    return _jsx("div", { children: children });
}
export class MultiModal extends Component {
    constructor(props) {
        super(props);
        const { page } = props;
        this.state = {
            visiblePage: isUndefined(page) ? 0 : page,
        };
        this.goToNext = this.goToNext.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { page } = nextProps;
        if (isUndefined(page) || page === this.props.page) {
            return;
        }
        this.goToPage(page);
    }
    goToNext() {
        const { children } = this.props;
        const newI = this.state.visiblePage + 1;
        if (Children.count(children) <= newI) {
            return;
        }
        this.goToPage(newI);
    }
    goToPage(i) {
        this.setState({ visiblePage: i });
        const { onPageMove } = this.props;
        if (isFunction(onPageMove)) {
            onPageMove(i);
        }
    }
    render() {
        const { children, title, isOpen, onClose, height, size, backgroundImage, nextLabel, doneLabel } = this.props;
        const { visiblePage } = this.state;
        const { goToPage, goToNext } = this;
        const onDone = this.props.onDone || onClose;
        const pageCount = Children.count(children);
        const isLastPage = visiblePage === pageCount - 1;
        const modalProps = {
            title,
            isOpen,
            onClose,
            backgroundImage,
            size: size || DEFAULT_SIZE,
        };
        const sliderProps = {
            page: visiblePage,
            height,
        };
        const pagerProps = { visiblePage, pageCount, goToPage };
        const buttonProps = isLastPage
            ? {
                children: doneLabel || 'Done',
                onClick: onDone,
            }
            : {
                children: nextLabel || 'Next',
                onClick: goToNext,
            };
        return (_jsxs(Modal, Object.assign({}, modalProps, { children: [_jsx(SlideShow, Object.assign({}, sliderProps, { children: children })), _jsxs("div", { className: "multi-modal-footer", children: [_jsx(Pager, Object.assign({}, pagerProps)), _jsx(DeprecatedButtonContainer, { children: _jsx(Button, Object.assign({ kind: "PRIMARY" }, buttonProps)) })] })] })));
    }
}
function Pager({ visiblePage, goToPage, pageCount }) {
    const dots = [];
    for (let i = 0; i < pageCount; i++) {
        const className = classNames({
            'is-selected': visiblePage === i,
        });
        dots.push(_jsx(Button, { kind: "PLAIN", onClick: () => goToPage(i), className: className, children: _jsx("span", {}) }, i));
    }
    return _jsx("div", { className: "pagination-dot-container", children: dots });
}
MultiModal.propTypes = propTypes;
