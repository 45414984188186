import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCurrentUser } from '../../../../services/CurrentUser';
import * as autocompletion from '../../helpers/autocompletion';
import ToolbarButton from '../../Toolbar/button';
import { IconAtSign } from 'featherico';
import Mention from './format';
import UserSearcher from './UserSearcher';
const NAME = 'mentions';
const TRIGGER = '@';
const TRIGGERS = [{ type: 'string', value: TRIGGER }];
const searcher = new UserSearcher();
function Choice(props) {
    const { item } = props;
    return (_jsxs(autocompletion.ResultsItem, Object.assign({}, props, { children: [_jsx("img", { src: `${item.logo}?size=20`, alt: "" }), _jsx("span", { children: item.name })] })));
}
class Mentions {
    constructor(quill) {
        this.check = index => this.instance.checkDeferred({ index, length: 0 }, TRIGGERS);
        const container = quill.addContainer('ql-mention');
        this.instance = autocompletion.createInstance({
            quill,
            container,
            wrapperComponent: autocompletion.ResultsContainer,
            itemComponent: Choice,
            moreItemsComponent: autocompletion.ResultsMoreItems,
            maxItemsShown: 5,
            onSearch: query => searcher.search(query.string.slice(1), getCurrentUser()),
            onSelect: (item, query) => {
                this.instance.hide();
                autocompletion.replaceQueryWithEmbed(quill, query, Mention.blotName, {
                    value: item.name,
                    serialization: `${TRIGGER}${item.loginname}`,
                });
            },
        });
        this.instance.listen(TRIGGERS);
    }
}
export const name = NAME;
export const handler = Mentions;
export const formats = [Mention];
export const toolbarElements = [
    {
        key: 'mentions',
        type: 'button',
        component: ({ quill, getLastSelection }) => {
            const onClick = () => {
                const { index } = getLastSelection();
                const nextIndex = index + TRIGGER.length;
                quill.insertText(index, TRIGGER, 'user');
                quill.setSelection(nextIndex);
                quill.getModule(NAME).check(nextIndex);
            };
            return (_jsx(ToolbarButton, { onClick: onClick, title: "Mention", children: _jsx(IconAtSign, {}) }));
        },
    },
];
