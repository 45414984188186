import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Waiting from './presenter/Waiting';
import Result from './presenter/Result';
import api from '../../../api';
const modalConfig = {
    DEFAULT: { status: undefined, size: 'MD_WIDTH' },
    PROGRESS: { status: 'waiting', size: 'MD_WIDTH' },
    DONE: { status: 'success', size: 'MD_WIDTH' },
};
class ExportModal extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            status: 'DEFAULT',
            file: null,
        };
        this.intervalFnId = undefined;
        this.handleStart = (downloadableFile) => {
            this.setState({ status: 'PROGRESS' });
            this.intervalFnId = setInterval(() => {
                api.downloads.getFile(downloadableFile.id).then(d => {
                    if (!d.canBeDownloaded) {
                        return;
                    }
                    this.setState({
                        status: 'DONE',
                        file: d,
                    });
                    this.cleanup();
                });
            }, 3000);
        };
    }
    cleanup() {
        if (this.intervalFnId) {
            clearInterval(this.intervalFnId);
        }
    }
    componentWillUnmount() {
        this.cleanup();
    }
    render() {
        const { status, file } = this.state;
        const { bodyComponent: BodyComponent, onClose, isOpen } = this.props;
        return (_jsx(Modal, { noCloseIcon: false, isOpen: isOpen, onClose: this.props.onClose, status: modalConfig[status].status, size: modalConfig[status].size, children: status === 'PROGRESS' ? (_jsx(Waiting, {})) : status === 'DONE' ? (_jsx(Result, { file: file, onClose: onClose })) : (_jsx(BodyComponent, { onExport: this.handleStart, onCancel: onClose })) }));
    }
}
ExportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    bodyComponent: PropTypes.func,
};
export default ExportModal;
