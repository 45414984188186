import queryString from 'query-string';
import { get } from '../request';
import { API_URL } from '../../../app-config/constants';
export function search(query, config = {}) {
    const params = {
        term: query,
        includeGuests: !!(config.searchForUsers || {}).includeGuests,
        showDeactivated: !!(config.searchForUsers || {}).includeDeactivated,
        companyId: config.companyId,
    };
    return get(`${API_URL}/users/search-wicket?${queryString.stringify(params)}`);
}
