import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import c from 'classnames';
import { IconChevronDown, IconX } from 'featherico';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import api from '../../../../api';
import { getCurrentUser } from '../../../services/CurrentUser';
import ClickOutside from '../../ClickOutside';
import { InputButtonIcon } from '../../Input/InputButton';
import { InputIcon } from '../../Input/InputIcon';
import TimePickerPopup from './TimePickerPopup';
import { format12Hour, isValidTime, toValidTime } from './TimePickerPopup/time';
import styles from './style.m.less';
const EMPTY_VALUE = '';
const KINDS_CLASSMAP = {
    DEFAULT: c(styles.kindDefault),
    TITLE: c(styles.kindTitle),
};
export default function TimePicker({ id, kind, format = '24hr', timeZone, value: initialValue, onChange, stepSizeMins, isClearable = false, hasError: initialHasError = false, disabled = false, readOnly = false, placeholder = 'Select time', showIcon = true, centerText = false, }) {
    const [value, setValue] = useState(initialValue || EMPTY_VALUE);
    const [inputValue, setInputValue] = useState(format === '12hr' ? format12Hour(value) : value);
    const [hasError, setHasError] = useState(initialHasError);
    const [minuteStepSize, setMinuteStepSize] = useState(stepSizeMins);
    const fetchDateTimeSettings = async () => {
        if (stepSizeMins === undefined) {
            const res = await api.companyDateTimeSettings
                .getDateTimeSettings(getCurrentUser().company.id)
                .then(response => response.minuteStepSize);
            if (res === undefined)
                setMinuteStepSize(15);
            setMinuteStepSize(res);
            return res;
        }
    };
    const handleValueChange = (newValue) => {
        if (!isValidTime(newValue, isClearable)) {
            setHasError(true);
            return;
        }
        setHasError(false);
        setValue(newValue);
        if (isClearable && newValue === EMPTY_VALUE) {
            onChange(undefined);
        }
        else {
            onChange(toValidTime(newValue));
        }
    };
    useEffect(() => {
        if (initialValue && initialValue !== value) {
            setInputValue(format === '12hr' ? format12Hour(initialValue) : initialValue);
            setValue(initialValue);
            setHasError(false);
        }
    }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchDateTimeSettings();
    });
    useEffect(() => {
        setHasError(initialHasError);
    }, [initialHasError]);
    const [isOpen, setOpen] = useState(false);
    const [skipBlur, setSkipBlur] = useState(false);
    const [isFocussed, setIsFocussed] = useState(false);
    const inputRef = useRef(null);
    const handleInputClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setIsFocussed(true);
        setOpen(true);
    };
    const handleInputChange = (event) => {
        const newValue = event.currentTarget.value;
        setValue(newValue);
        setInputValue(newValue);
        handleValueChange(newValue);
    };
    const handlePickerChange = (newValue) => {
        setValue(newValue);
        setInputValue(format === '12hr' ? format12Hour(newValue) : newValue);
        setHasError(false);
        handleValueChange(newValue);
    };
    const closePicker = () => setOpen(false);
    const clearValue = (event) => {
        event.stopPropagation();
        setInputValue(EMPTY_VALUE);
        handleValueChange(EMPTY_VALUE);
        closePicker();
    };
    const handlePopupMouseDown = () => setSkipBlur(true);
    const handleBlur = () => {
        setIsFocussed(false);
        if (skipBlur) {
            setSkipBlur(false);
            return;
        }
        closePicker();
    };
    const handleFocus = () => {
        setIsFocussed(true);
        setOpen(true);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            closePicker();
        }
    };
    const isInteractive = !disabled && !readOnly;
    const showClearButton = showIcon && isClearable && isInteractive && value !== EMPTY_VALUE;
    return (_jsx("div", { onBlur: handleBlur, className: c(KINDS_CLASSMAP[kind], styles.wrapper, {
            [styles.twelveHour]: format === '12hr',
            [styles.twentyFourHour]: format === '24hr',
            [styles.alignCenter]: centerText,
        }), children: _jsx(ClickOutside, { onClickOutside: closePicker, enabled: isOpen, children: _jsxs(Manager, { children: [_jsx(Reference, { children: ({ ref }) => (_jsxs(_Fragment, { children: [_jsxs("div", { ref: ref, className: c(styles.box, {
                                        [styles.withIcon]: showIcon,
                                        [styles.invalid]: hasError,
                                        [styles.disabled]: disabled,
                                        [styles.readOnly]: readOnly,
                                        [styles.focus]: isFocussed,
                                    }), onClick: isInteractive ? handleInputClick : undefined, role: "textbox", children: [_jsx("input", { id: id, type: "text", value: inputValue, className: styles.input, onChange: handleInputChange, onFocus: isInteractive ? handleFocus : undefined, placeholder: placeholder, ref: inputRef, "aria-invalid": hasError, "aria-label": "Time in format hour hour colon minute minute", disabled: disabled, readOnly: readOnly, onKeyDown: handleKeyDown, maxLength: format === '24hr' ? 5 : 8 }), showIcon && (_jsx(InputIcon, { small: true, className: c(styles.chevron, {
                                                [styles.hideOnHover]: showClearButton,
                                                [styles.disabled]: disabled,
                                            }), "aria-hidden": true, children: _jsx(IconChevronDown, {}) }))] }), showClearButton && (_jsx(InputButtonIcon, { className: styles.clearButton, onClick: clearValue, size: kind, "aria-label": "Clear Time", children: _jsx(IconX, {}) }))] })) }), isOpen && (_jsx(Popper, { placement: "bottom-start", children: ({ ref, style }) => (_jsx("div", { ref: ref, style: style, className: styles.popup, children: _jsx("div", { className: c(styles.selectorWrapper, {
                                    [styles.format12Hr]: format === '12hr',
                                }), onMouseDown: handlePopupMouseDown, children: _jsx(TimePickerPopup, { timeZone: timeZone, format: format, value: value, onChange: handlePickerChange, stepSizeMins: minuteStepSize }) }) })) }))] }) }) }));
}
