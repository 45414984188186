import { search } from '../../../api/custom/QueryUsersJson';
import { getCurrentUser } from '../CurrentUser';
import { refineSearchResult } from './search-users-helper';
import { getManagersReports } from '../User';
const currentUser = getCurrentUser();
export function searchForUsers(searchResult) {
    return Promise.all([search(searchResult.query, searchResult.config), getManagersReports(currentUser)])
        .then(([result, managerReports]) => refineSearchResult(currentUser, managerReports, searchResult, result))
        .catch(() => searchResult);
}
