import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconDownload } from 'featherico';
import { DOWNLOADS } from '../../../../routes';
import { prependAppPath } from '../../../services/Url';
import Button from '../../Button';
import DeprecatedButtonContainer from '../../ButtonContainer';
import Link from '../../Link';
import prettyFileSize from '../services/prettyFileSize';
import styles from './style.m.less';
import { preventJavascriptUrls } from '../../../../services/security';
export default function Result({ file, onClose }) {
    return (_jsxs("div", { children: [_jsx("h1", { children: "Finished" }), _jsxs("p", { children: ["Your export is available now. We also added it to the", ' ', _jsx(Link, { href: prependAppPath(DOWNLOADS), children: "latest exports" }), " page for future reference."] }), _jsx(Download, Object.assign({}, file)), _jsx(DeprecatedButtonContainer, { children: _jsx(Button, { onClick: onClose, children: "Close" }) })] }));
}
function Download({ downloadPath, fileName, size }) {
    return (_jsxs("div", { children: [_jsx(IconDownload, {}), "\u00A0", _jsx("b", { children: _jsx("a", { href: preventJavascriptUrls(downloadPath), children: fileName }) }), "\u00A0", _jsxs("span", { className: styles.filesize, children: ["(", prettyFileSize(size), ")"] })] }));
}
